<template>
	<v-container>
		<div
			:style="{
				marginBottom: '10px',
				fontWeight: 'bold',
				fontSize: fontsize + 'px',
			}"
		>
			<span 
				:style="{
					marginLeft: '10px',
					fontSize: fontsize + 'px',
				}"
			>
				2021年10月26日（木） 22:13 時点
			</span>
			<span 
				:style="{
					marginLeft: '10px',
					fontSize: fontsize + 'px',
				}"
			>
				最新データ
			</span>
		</div>
		<div
			:style="[
				{
					height: '60px',
					color: 'black',
					'font-size': fontsize + 'px',
					'line-height': '60px',
					'font-weight': 'bold',
					'vertical-align': 'middle',
					'background-color': alertColor(item),
					'margin-bottom': '20px'
				},
			]"
		>
			<span style="margin-left: 20px">{{ item.id }}</span>
			<span style="margin-left: 20px">{{ item.name }}</span>
			<span style="margin-left: 40px; font-size: 14px"
				>常時満水位より
			</span>
			<span style="margin-left: 15px;">+8.21m</span>
		</div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<div class="block-header">水位計推移</div>
				<div style="width: 100%; height:400px; background-color: #444">
					<div id="plot" style="height: 400px">
						<GChart
							type="ComboChart"
							:data="chartData"
							:options="chartOptions"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				cols="12"
				xs="12"
				sm="12"
				md="12"
				lg="12"
				xl="12"
				align="right"
			>
				<v-btn style="margin-right: 10px;" @click.stop="detailsGraph">グラフ詳細表示</v-btn>
				<v-btn style="margin-right: 10px;" @click.stop="forecastGraph">回復水位の予測</v-btn>
				<v-btn @click.stop="evaluateGraph">洪水調節効果評価</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<div class="block-header">現地マップ</div>
				<div style="width: 100%; height: 500px">
					<MapBlock
						:zoom="zoom"
						:markers="markers"
						:fitness="fitness"
						:center="center"
						style="width: 100%; height: 100%"
					></MapBlock>
				</div>
			</v-col>
			<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<v-row no-gutters>
					<v-col cols="12" xs="12" sm="12" md="12" log="12" xl="12">
						<div class="block-header">定点カメラ画像</div>
					</v-col>
				</v-row>
				<v-row no-gutters style="height: 250px;">
					<v-col cols="12" xs="12" sm="12" md="6" log="6" xl="6" style="background: #666; padding: 10px;" align="center">
						<div>
							<img
								src="/img/camera.png"
								style="width: 30px; vertical-align: middle"
							/>
							水門1
						</div>
						<img src="/img/img001.jpg" style="height: 160px;" />
					</v-col>
					<v-col cols="12" xs="12" sm="12" md="6" log="6" xl="6" style="background: #666; padding: 10px;" align="center">
						<div>
							<img
								src="/img/camera.png"
								style="width: 30px; vertical-align: middle"
							/>
							水門2
						</div>
						<img src="/img/img002.jpg" style="height: 160px;" />
					</v-col>
				</v-row>
				<v-row no-gutters style="height: 250px;">
					<v-col cols="12" xs="12" sm="12" md="6" log="6" xl="6" style="background: #666; padding: 10px;" align="center">
						<div>
							<img
								src="/img/camera.png"
								style="width: 30px; vertical-align: middle"
							/>
							水門3
						</div>
						<img src="/img/img001.jpg" style="height: 160px;" />
					</v-col>
					<v-col cols="12" xs="12" sm="12" md="6" log="6" xl="6" style="background: #666; padding: 10px;" align="center">
						<div>
							<img
								src="/img/camera.png"
								style="width: 30px; vertical-align: middle"
							/>
							水門4
						</div>
						<img src="/img/img002.jpg" style="height: 160px;" />
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<div class="block-header">基本情報</div>
				<div
					style="
						background-color: #666;
						border: 1px solid #666;
						width: 100%;
						height: 400px;
					"
				>
					<div
						style="
							display: block;
							width: 100%;
							text-align: left;
							margin-left: 15px;
							margin-top: 20px;
						"
					>
						<div style="font-weight: bold; color: #fff">
							管理番号: {{ item.id }}
						</div>
						<div style="font-weight: bold; color: #fff">
							エリア: {{ item.district }}
						</div>
						<div style="font-weight: bold; color: #fff">
							名称: {{ item.name }}
						</div>
						<div style="font-weight: bold; color: #fff">
							水位: {{ item.waterlevel }} {{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							水位しきい値1: {{ item.alertThresholds[0] }}
							{{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							水位しきい値2: {{ item.alertThresholds[1] }}
							{{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							水位しきい値3: {{ item.alertThresholds[2] }}
							{{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							水位しきい値4: {{ item.alertThresholds[3] }}
							{{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							左岸高さ: {{ item.leftShoreHeight }} {{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							右岸高さ: {{ item.rightShoreHeight }}
							{{ item.unit }}
						</div>
						<div style="font-weight: bold; color: #fff">
							緯度: {{ item.position.lat }}
						</div>
						<div style="font-weight: bold; color: #fff">
							経度: {{ item.position.lng }}
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<div class="block-header">水位計電圧推移</div>
				<div style="width: 100%; height: 400px; background-color: #444">
					<div id="plot" style="width: 99%; height: 400px">
						<GChart
							type="LineChart"
							:data="chartData2"
							:options="chartOptions2"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { GChart } from "vue-google-charts";
import MapBlock from "@/components/MapBlock.vue";

export default {
	computed: {
		item: function () {
			return this.$store.getters.getPlaceById(this.$route.params.id);
		},
		center: function () {
			return {
				lat: this.item.position.lat,
				lng: this.item.position.lng,
			};
		},
		markers: function () {
			let data = [];
			let element = {
				id: this.item.id,
				lat: this.item.position.lat,
				lng: this.item.position.lng,
				icon: {
					color: this.$store.getters.getAlertColor(this.item),
				},
			};
			data.push(element);
			return data;
		},
		alertColor() {
			return function (item) {
				return this.$store.getters.getAlertColor(item);
			};
		},
		chartData: {
			get() {
				return this.$store.state.waterlevelData[this.item.id];
			},
			set(value) {
				this.$store.state.waterlevelData[this.item.id] = value;
			},
		},
		chartData2: {
			get() {
				return this.$store.state.powerlevelData[this.item.id];
			},
			set(value) {
				this.$store.state.powerlevelData[this.item.id] = value;
			},
		},
	},
	components: {
		MapBlock,
		GChart,
	},
	mounted() {
		if (
			!this.item ||
			!this.$route.params.id ||
			!this.$route.params.id.match(/^([0-9]{1,4})/g)
		) {
			this.$router.push({ name: "Home" }).catch(() => {});
		}
		let body = document.getElementsByTagName("body")[0];
		if (body.clientWidth > 1000) {
			this.fontsize = 32;
		} else {
			this.fontsize = 18;
		}
		this.scrollTop();
	},
	created() {
		this.chartData = null;
		this.chartData2 = null;
		this.$store.dispatch("setWaterlevelData", { index: this.item.id });
		this.$store.dispatch("setPowerlevelData", { index: this.item.id });
	},
	methods: {
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		detailsGraph: function () {
			this.$router
				.push({ name: "DetailsGraph", params: { id: this.item.id, item: this.item } })
				.catch(() => {});
		},
		forecastGraph: function () {
			this.$router
				.push({ name: "ForecastGraph", params: { id: this.item.id, item: this.item } })
				.catch(() => {});
		},
		evaluateGraph: function () {
			this.$router
				.push({ name: "EvaluateGraph", params: { id: this.item.id, item: this.item } })
				.catch(() => {});
		},
	},
	data() {
		return {
			fontsize: 0,
			// Google Chart
			chartOptions: {
				backgroundColor: "#444",
				series: {
					0: { lineWidth: 3, type: "line", targetAxisIndex: 0 },
					1: { lineWidth: 3, type: "line", lineDashStyle: [2, 2], targetAxisIndex: 0 },
					2: { type: "bars", targetAxisIndex: 1 },
				},
				seriesType: "bars",
				height: "400",
				annotations: {
					textStyle: {
						color: "#ffffff",
						auraColor: "#ffffff",
					},
				},
				explorer: {
					actions: ["dragToZoom", "rightClickToReset"],
					axis: "horizontal",
					keepInBounds: true,
					maxZoomIn: 100.0,
				},
				hAxis: {
					format: "MM/dd hh:mm",
					gridlines: {
						count: 8,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
					slantedText: true,
					slantedTextAngle: 50,
					showTextEvery: 4,
					// title: "時刻",
					// titleTextStyle: {
					// 	color: "#ffffff",
					// },
				},
				vAxes: {
					0: {
						title: "水位 (m)",
						titleTextStyle: {
							color: "#ffffff"
						}
					},
					1: {
						title: "降雨量 (mm)",
						titleTextStyle: {
							color: "#ffffff"
						}
					},
				},
				vAxis: {
					gridlines: {
						count: 6,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
				},
				legend: {
					position: "top",
					textStyle: {
						color: "#ffffff",
					},
				},
				chartArea: {
					backgroundColor: {
						stroke: "#666",
						strokeWidth: 1,
					},
					top: "10%",
					left: "8%",
					right: "8%",
					bottom: "30%",
					width: "100%",
					height: "100%",
				},
				colors: ["ffffff", "ffffff", "0000cd"]
			},
			chartOptions2: {
				backgroundColor: "#444",
				lineWidth: 8,
				series: { 0: { type: "line", lineDashStyle: [2, 2] } },
				height: "380",
				annotations: {
					textStyle: {
						color: "#ffffff",
						auraColor: "#ffffff",
					},
				},
				explorer: {
					actions: ["dragToZoom", "rightClickToReset"],
					axis: "horizontal",
					keepInBounds: true,
					maxZoomIn: 100.0,
				},
				hAxis: {
					gridlines: {
						count: 6,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
					slantedText: true,
					slantedTextAngle: 50,
					showTextEvery: 1,
					title: "時刻",
					titleTextStyle: {
						color: "#ffffff",
					},
				},
				vAxis: {
					gridlines: {
						count: 6,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
					title: "電圧",
					titleTextStyle: {
						color: "#ffffff",
					},
				},
				legend: {
					position: "none",
				},
				chartArea: {
					backgroundColor: {
						stroke: "#666",
						strokeWidth: 1,
					},
					top: "10%",
					left: "8%",
					right: "5%",
					bottom: "20%",
					width: "100%",
					height: "100%",
				},
				colors: ["#ff0022"],
			},
			// Google Map
			zoom: 15,
			fitness: false,
		};
	},
};
</script>

<style>
.block-header {
	background-color: #000;
	color: #fff;
	padding: 3px 3px 3px 6px;
	font-size: 12px;
	width: 100%;
	height: 20px;
}
.grid-wrapper {
	display: grid;
	grid-template-columns: 50% 50%;
	background: #666;
	width: 100%;
	margin: auto;
	height: 300px;
	padding-top: 4%;
	padding-left: 10%;
	color: white;
}
</style>